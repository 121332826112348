import "iframe-resizer";

/* this file is needed to have the possibility to adapt the
* implementation of the wizard without any change from the clients.  */

window.initFpreWidget = (iFrameId, options = {gtmEnabled: false}) => {
  if (options.gtmEnabled === true) {
    window.dataLayer = window.dataLayer || [];
    window.onmessage = function (e) {
      if (e.data && e.data.type == 'gtm-tracking-event') {
        window.dataLayer.push(e.data.content);
      }
    };
  }
  const customMessageHandler = (options) => {
    const handleSmoothScrollToOffset = (offsetTop) => {
      window.scrollTo({top: offsetTop, behavior: 'smooth'});
    }

    if (options?.message?.type === 'smoothScrollToOffset') {
      handleSmoothScrollToOffset(options.message.top);
    }
  };

  iFrameResize({log: false, checkOrigin: false, onMessage: customMessageHandler, ...options}, iFrameId);
}
